export const gaTracking = {
    trackingCode: "UA-45872048-5"
};

export const airTable = {
    apiKey: "keyGNXEQeO6XlZw36" 
    , bases: {
        events: "appQdo8rAUQBlW0Ja"
    }
}

export enum Colors {
    White = "#ffffff"
    , MintGreen = "#88EAB5"
    , LtMintGreen = "#CEFFE5"
    , ElectricBlue = "#0FA3F5"
    , SunsetOrange = "#FFBA7B"
    , LtSunsetOrange = "#FFD8B4"
    , Black = "#000000"
    , DkGray = "#2E2E2E"
    , MdGray = "#494949"
    , LtGray = "#4F4F4F"
    , XltGray = "#A3A3A3"

}

export const urls = {
    resumeUrl: ""
    , mailtoUrl: "mailto:efarinholt@gmail.com"
    , gigBookingLink: "https://calendly.com/efarinholt/gig-or-rehearsal"
    , lessonBookingLink: "https://calendly.com/efarinholt/drum-lesson"
}