
import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faCalendar } from "@fortawesome/free-solid-svg-icons";
import { faInstagram, faSoundcloud } from "@fortawesome/free-brands-svg-icons";
import ReactGA from 'react-ga';
import { urls, gaTracking, airTable } from '../Helpers/Enums';
import Moment from 'react-moment';
import Loader from "react-loader-spinner";

ReactGA.initialize(gaTracking.trackingCode, {
    debug: false,
    titleCase: false,
    gaOptions: {
        siteSpeedSampleRate: 100   
    }
});
ReactGA.pageview("/");

interface IProps {}
interface IEvent {
    Name: string;
    Venue: string;
    Date: Date;
    Time: string;
    Address: string;
    CityState: string;
    URL?: string;
}
interface IState {
    events: IEvent[];
    eventsLoading: boolean;
}
export default class Music extends Component<IProps, IState>  {
    constructor(props: IProps){
        super(props);
        this.state = {
            events: []
            , eventsLoading: true
        }
    }
    fetchEvents(){
        let records: IEvent[] = [];

        fetch(`https://api.airtable.com/v0/${airTable.bases.events}/EventsList?api_key=${airTable.apiKey}`)
            .then(res => res.json())
            .then(res => {
                res.records.forEach((record: any) => {
                    records.push(record.fields);
                });
            })
            .then(() => {
                this.setState({
                    events: records
                    , eventsLoading: false
                });
            })
            .catch(error => console.log(error))
        
    }

    componentDidMount() {
        this.fetchEvents();
	}
    render(){
        const handleCTAClick = (url: string, label: string, eventCategory: string) => {
            ReactGA.event({
                category: eventCategory,
                action: label
            });
            window.open(url, "_blank");
        }

        const socialLink = (icon: JSX.Element, label: string, url: string, event: string) => 
            <div className="social-link-container d-flex pb-1 pt-1">
                <button
                    className="btn btn-sm btn-link link-primary d-flex flex-row align-items-center pr-3"
                    onClick={() => handleCTAClick(url, event, "Social CTA")}>
                    
                    <div className="pr-2">{icon}</div>
                    <span className="text-white special-link">{label}</span>
                </button>
            </div>;

        const bookingButton = (label: string, url: string) => 
            <button className="btn btn-lg btn-primary" onClick={() => handleCTAClick(url, label, "Booking CTA")}>
                <FontAwesomeIcon className="mr-3" icon={faCalendar} /> 
                {label}
            </button>
        
        const eventItem = (date: string, time: string, title: string, venue: string, address: string, cityState: string, url?: string) => 
            <>    
                <Col md={12} lg={3} className="pr-4 d-flex flex-row align-items-center">
                    <FontAwesomeIcon className="text-primary mr-3" icon={faCalendar} /> 
                    <div className="d-flex flex-row flex-lg-column">
                        <span className="pr-2">
                            <Moment format="MMM D" withTitle>
                                {date}
                            </Moment>,
                        </span>
                        <span>{time}</span>
                    </div>
                </Col>

                <Col md={12} lg={6} className="pl-2 pr-4 title-block d-flex flex-column">
                    <span><strong>{title}</strong></span>
                    <span>{venue}</span>
                    {url !== "" && 
                        <a href={url} target="_blank" rel="noopener noreferrer" className="link-primary d-flex flex-row pr-3">
                            {url}
                        </a>
                    }
                </Col>

                <Col md={12} lg={3} className="pl-2 d-flex flex-row align-items-center">
                    <div className="d-flex flex-row flex-lg-column flex-start align-items-start">
                        <span className="pr-2">{address},</span>
                        <span>{cityState}</span>
                    </div>
                </Col>
                
            </>

        return (
            <>
                <header>
                    <Container>
                        <Row className="pt-5 pb-5">
                            <Col md={12} lg={9} className="d-flex flex-column justify-content-center"> 
                                <h1>Evan Farinholt</h1>
                                <h2>Musician &amp; Educator</h2>
                                <div className="d-flex pt-2 pb-2 flex-lg-row flex-column">
                                    {socialLink(
                                        <FontAwesomeIcon className="text-primary" icon={faInstagram} /> 
                                        , "evanfarinholt"
                                        , "https://www.instagram.com/evanfarinholt/"
                                        , "Instagram"
                                    )}
                                    {socialLink(
                                        <FontAwesomeIcon className="text-primary" icon={faEnvelope} /> 
                                        , "efarinholt@gmail.com"
                                        , "mailto:efarinholt@gmail.com"
                                        , "Email"
                                    )}
                                    {socialLink(
                                        <FontAwesomeIcon className="text-primary" icon={faSoundcloud} /> 
                                        , "evanfarinholt"
                                        , "https://soundcloud.com/evanfarinholt"
                                        , "SoundCloud"
                                    )}
                                </div>
                            </Col>
                            <Col md={3} className="d-none d-lg-flex justify-content-end d-sm-none hidden-xs-down">
                                <img alt="" className="img-fluid" src="/maine-circle-graphic.svg" />
                            </Col>
                        </Row>
                    </Container>
                </header>
                <div className="drumsticks-background">
                    <Container>
                        <Row className="pt-3 pb-3">
                            <Col md={12} lg={10}>
                                <h3>I’m a <strong>drummer</strong>, <strong>drum teacher</strong>, and <strong>music maker</strong> based out of Kittery, Maine.</h3>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} lg={8}>
                                <p>I’m available for <strong>teaching</strong>, <strong>performing</strong>, and <strong>song writing projects</strong> in the Seacoast region. Book a session with me if you’re interested.</p>
                            </Col>
                        </Row>
                        <Row className="pt-3 pb-3">
                            <Col className="d-flex flex-column flex-start align-items-start">
                                <div className="pb-3">
                                    {bookingButton(
                                        "Schedule a Drum Lesson"
                                        , urls.lessonBookingLink
                                    )}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
                <Container className="d-md-none d-lg-flex">
                    <Row className="pt-3 pb-3">
                        <Col></Col>
                    </Row>
                </Container>
                
                <Container>
                    <Row className="pt-3 pb-3">
                        <Col>
                            <h4>Upcoming Events</h4>
                        </Col>
                    </Row>
                </Container>

                
                <Container className="pt-3 pb-5">
                    {this.state.eventsLoading ? 
                    // To Do: look into lazy-loading component - https://www.npmjs.com/package/react-lazy-load
                        <Row>
                            <Col className="d-flex justify-content-center">
                                <Loader
                                    type="TailSpin"
                                    color="#D36262"
                                    height={60}
                                    width={60}
                                    timeout={3000}
                                />
                            </Col>
                        </Row>
                        :
                        <>
                            {this.state.events.length > 0
                                ? this.state.events.map((event: any, i: number) => {
                                    return(
                                        <Row className="event-item pt-3 pb-3" key={i}>
                                            {eventItem(
                                                event.Date
                                                , event.Time
                                                , event.Name
                                                , event.Venue
                                                , event.Address
                                                , event.CityState
                                                , event.URL
                                            )}
                                        </Row>
                                    )
                                })
                                :
                                    <Row>
                                        <Col className="d-flex flex-column">
                                            <p className="text-secondary">
                                                No Upcoming Events. <a href={urls.gigBookingLink} target="_blank" rel="noopener noreferrer">Book a gig</a>.
                                            </p>
                                        </Col>
                                    </Row>
                            }
                        </>
                    }
                </Container>
            </>
        )
    }
}
